<template>
  <section
    class="lg:w-8/12 md:w-10/12 container mx-auto md:p-0 px-4 md:my-32 my-8 relative"
  >
    <h1 class="my-4 text-black md:pb-5 pb-2">Upcoming Events</h1>
    <div v-if="loading" class="text-center">
      <Spinner class="w-10 h-10" />
    </div>
    <div class="flex flex-col" v-else>
      <template v-if="shows && shows.length">
        <ShowCard
          class="mb-4"
          v-for="(show, index) in shows"
          :key="index"
          :show="show"
        />
      </template>
      <p class="sub-heading font-medium">
        Sorry, there are no events available at the moment.
      </p>
    </div>
  </section>
</template>
<script>
import {
  fetchShows,
  createStripeCheckoutSession,
} from "@/services/SessionService";
import { useToast } from "vue-toastification";
import { useSessionStore } from "@/stores/session";
import ShowCard from "../components/ShowCard.vue";
import Spinner from "@/components/svg/Spinner.vue";
import { useHead } from "@unhead/vue";
import { showsMetaTags } from "../meta/metaData";
import MicroModal from "micromodal";
import { useCookies } from "vue3-cookies";
export default {
  components: {
    ShowCard,
    Spinner,
  },
  data() {
    return {
      shows: [],
      loading: false,
    };
  },
  setup() {
    const session = useSessionStore();
    const toast = useToast();
    const { cookies } = useCookies();

    useHead({
      meta: showsMetaTags(),
    });
    return { session, toast, cookies };
  },
  methods: {
    async fetchShows() {
      this.loading = true;
      await fetchShows()
        .then(
          (res) => (this.shows = res.res.data),
          ({ status }) => this.toast.error("Oops.. something went wrong.")
        )
        .finally(() => (this.loading = false));
    },
    async purchaseShow(productId) {
      await createStripeCheckoutSession({ productId }).then(
        (res) => (window.location.href = res.data.url)
      );
    },
  },
  mounted() {
    document.body.classList.add("bg-cream-white");
    this.fetchShows();

    if (
      this.$route.query?.success &&
      !this.cookies.get("subscribed-modal-shown")
    ) {
      MicroModal.show("subscribed");
      this.cookies.set("subscribed-modal-shown", true);
    }
  },
};
</script>
