<template>
  <section
    class="lg:w-3/5 container mx-auto md:p-0 px-4 my-32 relative flex justify-center items-center flex-col"
  >
    <div class="w-full mb-8">
      <h1 class="my-4 text-black text-center">
        Welcome back! Existing users can log in here.
      </h1>
      <div
        class="md:mt-12 mt-6 flex flex-col md:flex-row md:items-center items-start md:justify-center"
      >
        <GetStartedForm button-text="Log In" textColor="black" :isDark="true" />
      </div>
    </div>
  </section>
</template>
<script>
import GetStartedForm from "../components/GetStartedForm.vue";
import { useHead } from "@unhead/vue";
import { signInMetaTags } from "../meta/metaData";
export default {
  setup() {
    useHead({
      meta: signInMetaTags(),
    });
  },
  components: {
    GetStartedForm,
  },
  mounted() {
    document.body.classList.add("bg-cream-white");
    document.body.classList.remove("bg-p-black");
  },
};
</script>
