<template>
  <section
    class="md:mt-24 mt-16 md:w-6/12 xl:w-4/12 container mx-auto md:p-0 px-4 relative flex justify-center items-center flex-col"
  >
    <div class="w-full mb-8">
      <p class="font-semibold tracking-wider uppercase">Step 1</p>
      <h1 class="small my-4">Welcome!</h1>
      <p class="body-text">
        Please enter the code we sent to the phone number
        <span class="font-bold tracking-wider underline"
          >({{ session.tel }})</span
        >
        you provided.
      </p>
      <div class="mt-6">
        <p class="font-semibold mb-2">Verification Code</p>
        <div class="grid grid-cols-6 gap-2">
          <input
            ref="digit0"
            v-model="inputs[0]"
            @keydown="filterInput($event, false)"
            @keyup="focusOnNextInput($event, false, 'digit1')"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
          <input
            ref="digit1"
            v-model="inputs[1]"
            @keydown="filterInput($event, 'digit0')"
            @keyup="focusOnNextInput($event, 'digit0', 'digit2')"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
          <input
            ref="digit2"
            v-model="inputs[2]"
            @keydown="filterInput($event, 'digit1')"
            @keyup="focusOnNextInput($event, 'digit1', 'digit3')"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
          <input
            ref="digit3"
            v-model="inputs[3]"
            @keydown="filterInput($event, 'digit2')"
            @keyup="focusOnNextInput($event, 'digit2', 'digit4')"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
          <input
            ref="digit4"
            v-model="inputs[4]"
            @keydown="filterInput($event, 'digit3')"
            @keyup="focusOnNextInput($event, 'digit3', 'digit5')"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
          <input
            ref="digit5"
            v-model="inputs[5]"
            @keydown="filterInput($event, 'digit4')"
            @keyup="focusOnNextInput($event, 'digit4', false)"
            class="border-2 text-2xl text-center border-dark-cream xs:p-4 p-2 bg-washed-white md:py-5"
            minlength="1"
            maxlength="1"
            type="text"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 w-full mt-4">
        <div class="flex flex-col">
          <p>Didn't recieve a code?</p>
          <div>
            <a
              @click="requestOTP"
              class="font-bold cursor-pointer hover:border-b hover:border-black inline-block"
            >
              Resend Code
            </a>
          </div>
        </div>
        <!-- <div class="flex justify-end">
          <div>
            <a
              @click="pasteCode"
              class="font-bold cursor-pointer hover:border-b hover:border-black inline-block"
              href="#"
              >Paste Code</a
            >
          </div>
        </div> -->
      </div>
      <div class="mt-8 w-full">
        <div v-if="busy">
          <SvgSpinner />
          <p class="mt-4">Verifying...</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "pinia";
import { useSessionStore } from "@/stores/session";
import { isOTP, requestOTP, login } from "@/services/SessionService";
import { useToast } from "vue-toastification";
import SvgSpinner from "@/components/svg/Spinner.vue";
import { useHead } from "@unhead/vue";
import { codeMetaTags } from "../meta/metaData";
import { parsePhoneNumber } from "libphonenumber-js";
import MicroModal from "micromodal";
export default {
  components: {
    SvgSpinner,
  },
  setup() {
    const session = useSessionStore();
    const toast = useToast();

    useHead({
      meta: codeMetaTags(),
    });
    return { session, toast };
  },
  data() {
    return {
      busy: false,
      unathorized: false,
      inputs: ["", "", "", "", "", ""],
    };
  },
  computed: {
    ...mapState(useSessionStore, ["tel"]),
    code() {
      const s = this.inputs.join("");
      return isOTP(s) ? s : false;
    },
  },
  methods: {
    pasteCode() {
      navigator.clipboard.readText().then((cliptext) => {
        const code = cliptext;
        for (let i = 0; i < 6; i++) {
          this.inputs[i] = code[i];
        }
      });
    },
    filterInput(e, prev) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if ((keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 107)) {
        return; // permit keyboard, numpad
      } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 86) {
        this.pasteCode();
      } else if (e.key === "Backspace" || e.code === "Backspace") {
        if (prev === "digit4" && this.$refs["digit5"].value.length > 0) {
          return;
        } else if (prev !== false) {
          this.$refs[prev].focus();
        }
        return; // permit backspace
      }
      e.preventDefault();
    },
    focusOnNextInput(e, prev, next) {
      var keyCode = e.keyCode ? e.keyCode : e.which;
      if (e.key === "Backspace") {
        return;
      } else {
        if (
          (next !== false && keyCode > 47 && keyCode < 58) ||
          (keyCode > 95 && keyCode < 107)
        ) {
          this.$refs[next].focus();
        } else {
          this.submitOTP();
        }
      }
    },
    async requestOTP() {
      const parsedPhoneNumber = parsePhoneNumber(this.session.tel, "US");
      if (parsedPhoneNumber.isPossible() === false) {
        this.toast.error("Make sure you're using a valid phone number.");
        this.loading = false;
        return;
      }
      await requestOTP({ tel: this.tel }).then(
        () => {
          this.toast.success("A new code was sent to your phone.");
          this.inputs = ["", "", "", "", "", ""];
          this.$refs.digit0.focus();
        },
        ({ status }) => {
          this.toast.error("Oops.. something went wrong.");
        }
      );
    },
    async submitOTP() {
      const code = this.code;
      console.info(`CodeView: the code is ${code}`);
      console.info(`CodeView: the phone number is ${this.tel}`);
      if (code !== false) {
        this.busy = true;
        this.unauthorized = false;
        await login({ code }).then(
          (data) => {
            this.session.setProfileId(data.profile.id);
            this.session.setName(data.profile.name);

            let path = data?.data.checkout_session_url;
            if (path) {
              // Set Stripe Checkout URL in Pinia for next page
              this.session.setStripeCheckoutUrl(path);
            }

            window.location.href = data.next;
          },
          ({ status, unauthorized }) => {
            this.busy = false;
            if (unauthorized === true) {
              this.unathorized = true;
              this.toast.error("Code is Invalid.");
              console.log("error");
            } else {
              this.toast.error("Oops... something went wrong.");
            }
          }
        );
      }
    },
  },
  mounted() {
    document.body.classList.remove("bg-p-black");
    document.body.classList.add("bg-cream-white");

    this.$refs.digit0.focus();

    if (this.tel === false) {
      this.session.abandonSession();
      this.$router.push({ path: "/" });
    }
  },
};
</script>
