<template>
  <form @submit.prevent="getStarted" class="md:w-auto w-full" :id="telId + 'a'">

    <div class="flex md:flex-row flex-col">
      <span
        class="mb-2 md:hidden block text-base font-semibold leading-5 text-cream-white"
        :class="isDark ? 'text-black' : 'text-cream-white'"
      >
        Phone
      </span>
      <input
        :id="telId"
        required
        v-model="form.tel"
        class="phone bg-transparent lg:w-96 w-full border-2 md:border-r-0 md:py-3 py-2 px-6 md:mb-0 mb-3"
        :class="
          isDark
            ? 'border-black text-black placeholder-gray-700'
            : 'border-dark-cream text-dark-cream'
        "
        type="tel"
      />
      <button
        class="btn yellow whitespace-nowrap md:w-44"
        :class="{ 'has-border': isDark }"
      >
        <SvgSpinner v-if="busy" />
        <span v-else>Get Started</span>
      </button>
    </div>
    <div class="text-red-600 mt-2" v-if="error">{{ errorMessage }}</div>
  </form>
</template>
<script>
import { requestOTP } from "@/services/SessionService";
import { useToast } from "vue-toastification";
import { useSessionStore } from "@/stores/session";
import SvgSpinner from "@/components/svg/Spinner.vue";
import { parsePhoneNumber } from "libphonenumber-js";

import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

export default {
  components: {
    SvgSpinner,
  },
  setup() {
    const session = useSessionStore();
    const toast = useToast();
    return { session, toast };
  },
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    telId: {
      type: String,
      default: "phone-1",
    },
    textColor: {
      type: String,
      default: "white",
    },
  },
  data() {
    return {
      form: {
        tel: "",
      },
      busy: false,
      dialCode: "US",
      error: false,
      errorMessage: "",
      errorMap: [
        "Invalid number.",
        "Invalid country code.",
        "Number is too short.",
        "Number is too long.",
        "Invalid number.",
      ],
    };
  },
  methods: {
    async getStarted() {
      this.busy = true;

      const parsedPhoneNumber = parsePhoneNumber(this.form.tel, this.dialCode);
      if (parsedPhoneNumber.isPossible() === false) {
        this.toast.error("Make sure you're using a valid phone number.");
        this.busy = false;
        return;
      }
      console.log("hit");

      await requestOTP({ tel: parsedPhoneNumber.number })
        .then(
          () => {
            this.session.setUnverifiedTel(parsedPhoneNumber.number);
            this.$router.push(`/code`);
          },
          ({ status }) => this.toast.error("Oops.. something went wrong.")
        )
        .finally(() => (this.busy = false));
    },
  },
  mounted() {
    // intl-tel-input initilization
    const input = document.getElementById(this.telId);
    const iti = intlTelInput(input, {
      initialCountry: "us",
      nationalMode: true,
      excludeCountries: ["LB", "RU", "SY"],
      separateDialCode: true,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.0/js/utils.js",
    });
    // Validation of phone number entries
    input.addEventListener("keyup", () => {
      if (input.value.length && input.value.trim()) {
        if (iti.isValidNumber()) {
          this.error = false;
        } else {
          this.error = true;
          var errorCode = iti.getValidationError();
          this.errorMessage = this.errorMap[errorCode];
        }
      } else {
        this.error = false;
      }
    });

    input.addEventListener("countrychange", () => {
      const countryCodeData = iti.getSelectedCountryData();
      if (countryCodeData && countryCodeData.iso2) {
        this.dialCode = countryCodeData.iso2.toUpperCase();
      }
    });

    document.querySelector(
      `#${this.telId + "a"} .iti--separate-dial-code .iti__selected-dial-code`
    ).style.color = this.textColor;
  },
};
</script>
<style>
.iti--separate-dial-code .iti__selected-dial-code {
  color: white !important;
}
</style>
